import styled from "styled-components";
export const ContractsContainer = styled.section`
  background-color: var(--gray5, #e5e5e5);
  display: flex;
  flex-direction: column;
  padding: 6rem 8rem;
  gap: 2rem;
  width: 100%;
  max-width: 68.25rem;
  margin: 0 auto;
  min-height: 70vh;

  @media (max-width: 1023px) {
    padding: 3rem;
  }

  @media (max-width: 768px) {
    padding: 2rem;
    width: 100%;
    max-width: none;
  }

  @media (max-width: 480px) {
    padding: 1.5rem;
    width: 60%;
    max-width: none;
  }
`;

export const ContractsWrapper = styled.div`
  width: 100%;
  max-width: 59.25rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-family: "SourceSans3-Regular";
  color: var(--primary, #000);
  padding: 0 2rem;
  text-align: center;
  flex-grow: 1;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }

  @media (max-width: 480px) {
    max-width: 90%;
    padding: 0 1rem;
  }
`;

export const TitleText = styled.h2`
  font-family: "SourceSans3-Bold";
  color: var(--black2, #1d1d1d);
  font-size: 2rem;
  line-height: 2.2rem;
  text-align: center;

  & + & {
    margin-top: 2rem;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
`;

export const ContractsList = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;

export const ContractLink = styled.a`
  color: var(--primary, #000);
  text-decoration: none;
  display: block;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  word-wrap: break-word;
  word-break: break-word;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 0.8rem 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
    padding: 0.6rem 1rem;
  }
`;

export const ContractsItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: "SourceSans3-Regular";
  color: var(--primary, #000);
  width: 100%;
`;

export const BulletPoint = styled.span`
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--primary, #000);
  border-radius: 50%;

  /* Esconder o BulletPoint em dispositivos móveis */
  @media (max-width: 768px) {
    display: none;
  }
`;