import FooterComponent from "./components/FooterComponent";
import HeaderComponent from "./components/HeaderComponent";
import GlobalStyle from "./globalStyles/globalStyles";
import Home from "./components/Home";
import TermsOfService from "./components/TermsOfService";
import ContractComponent from "./components/ContractComponent";
import GlobalStorage from "./context/globalContext";
import ExitPopup from "./components/PopUpLeaveComponent"; // Importando o ExitPopup
import Cookie from "js-cookie";
import "@telefonica/mistica/css/mistica.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ThemeContextProvider, getMovistarSkin } from "@telefonica/mistica";
import CookieModal from "./components/CookieModal";

const misticaTheme = {
  skin: getMovistarSkin(),
  i18n: { locale: "pt-BR", phoneNumberFormattingRegionCode: "BR" },
};

function App() {
  const acceptCookies = Cookie.get("vivo-cookie-auth");

  const getBody = document.querySelector("body") as HTMLElement;

  if (!acceptCookies) {
    getBody.style.overflow = "hidden";
  } else {
    getBody.style.overflow = "unset";
  }

  return (
    //@ts-ignore
    <ThemeContextProvider theme={misticaTheme}>
      <GlobalStorage>
        <GlobalStyle />
        <Router>
          <HeaderComponent />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/normas-de-privacidade" element={<TermsOfService />} />
            <Route path="/contratos" element={<ContractComponent />} />
          </Routes>
        </Router>
        <ExitPopup />
        <FooterComponent />
        {!acceptCookies && <CookieModal />}
      </GlobalStorage>
    </ThemeContextProvider>
  );
}

export default App;
