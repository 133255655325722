import { useState, useContext, useEffect } from "react";
import { FormEvent } from "react";
import { GlobalContext } from "../../context/globalContext";
import { ButtonPrimary } from "@telefonica/mistica";
import * as S from "./styles";
import InputMask from "react-input-mask";
import SuccessMessage from "../SuccessMessage";
import handleResponsiveScroll from "../../mocks/handleResponsiveScroll";
import { api } from "../../lib/api";
import { formatAsciiCharacters } from "../../utils/formatAsciiCharacters";

interface IFormErrors {
  fullName: string;
  contact: string;
  email: string;
}

const defaultIneligibleFields = {
  fullName: "",
  contact: "",
  email: "",
};

const IneligibleScreen = () => {
  const {
    resultInformations,
    setResultInformations,
    setShowIneligibleScreen,
    setMonthlyValue,
    setSelectedCity,
    setSelectedState,
  } = useContext(GlobalContext);

  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [sendingFormData, setSendingFormData] = useState(false);
  const [formErrors, setFormErrors] = useState({} as IFormErrors);

  const [ineligibleFields, setIneligibleFields] = useState(
    defaultIneligibleFields
  );

  function handleChangeInputFieldValue(field: string, value: string) {
    setIneligibleFields((oldValue) => {
      return {
        ...oldValue,
        [field]: value,
      };
    });
  }

  function checkErrorsOnForm() {
    const errors = {} as IFormErrors;

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const cellPhoneRegex = /^\(\d{2}\) \d \d{4} \d{4}$/;

    if (!ineligibleFields.fullName) {
      errors.fullName = "Nome obrigatório";
    }

    if (!ineligibleFields.email) {
      errors.email = "E-mail obrigatório";
    } else if (!ineligibleFields.email.match(emailRegex)) {
      errors.email = "E-mail inválido";
    }

    if (!ineligibleFields.contact) {
      errors.contact = "Telefone obrigatório";
    } else if (!ineligibleFields.contact.match(cellPhoneRegex)) {
      errors.contact = "Telefone inválido";
    }

    setFormErrors(errors);
  }

  const formatName = formatAsciiCharacters(ineligibleFields.fullName);

  async function submitContact() {
    try {
      const dataResOnly = await api.post(
        import.meta.env.VITE_APP_API_WITHOUT_INVOICE,
        {
          nome: formatName,
          telefone: ineligibleFields.contact,
          email: ineligibleFields.email,
          valor_aproximado_fatura: resultInformations?.monthlyValue,
          municipio_ibge_id: resultInformations?.cityId,
          municipio: resultInformations?.city,
          origem: "migracoes",
          lead_origem: "migracoes-vivo",
        }
      );

      if (dataResOnly.data.success) {
        setShowSuccessScreen(true);
      } else {
        throw new Error(dataResOnly.data.mensagem_usuario);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setSendingFormData(false);
      setResultInformations(null);
    }
  }

  async function handleSubmitContact(e: FormEvent) {
    e.preventDefault();

    checkErrorsOnForm();

    setSendingFormData(true);
  }

  useEffect(() => {
    const isThereAnyErrors = Object.keys(formErrors);

    if (!isThereAnyErrors.length && sendingFormData) {
      submitContact();
    } else {
      setSendingFormData(false);
    }
  }, [formErrors]);

  function handleRedoSimulation() {
    setIneligibleFields(defaultIneligibleFields);

    setShowIneligibleScreen(false);
    setResultInformations(null);
    setSelectedCity(null);
    setMonthlyValue("");
    setSelectedState("");

    setTimeout(() => {
      handleResponsiveScroll(".economySimulation", { desk: -590, mob: -540 });
    }, 300);
  }

  useEffect(() => {
    handleResponsiveScroll(".inelegibleSimulation", { desk: -500, mob: -500 });
  }, []);

  const disabledSimulationBtn = sendingFormData;

  return (
    <>
      {!showSuccessScreen ? (
        <S.IneligibleRoot>
          <S.IneligibleWrapper className="inelegibleSimulation">
            <S.InfoCol>
              <S.MainHeader>
                Escolha a GUD e economize até 30% na conta de luz.
              </S.MainHeader>

              <S.HeaderText>
                A GUD cuida de todo o processo de migração sem nenhum custo e
                sem burocracia para sua empresa.
              </S.HeaderText>

              <S.RedoSimulation $hideOnMobile={true}>
                <button onClick={() => handleRedoSimulation()} type="button">
                  Simular novamente
                </button>
              </S.RedoSimulation>
            </S.InfoCol>

            <S.IneligibleFormCol>
              <S.IneligibleFormWrapper>
                <h2>
                  Neste momento nossa solução ainda não atende o seu perfil de
                  consumo.
                </h2>
                <br />
                <p>
                  Deixe seus dados que entraremos em contato<br></br> quando
                  tivermos novidades:
                </p>
                <S.IneligibleResultForm onSubmit={handleSubmitContact}>
                  <S.InputSection>
                    <label id="campoNome">
                      <input
                        onChange={({ target }) =>
                          handleChangeInputFieldValue("fullName", target.value)
                        }
                        type="text"
                      />
                      <S.FloatingText
                        $willFloat={Boolean(ineligibleFields.fullName)}
                        className={formErrors.fullName ? "errorMsg" : ""}
                      >
                        Nome e sobrenome
                      </S.FloatingText>
                      {formErrors.fullName && (
                        <S.FieldErrorMsg>{formErrors.fullName}</S.FieldErrorMsg>
                      )}
                    </label>
                    <label id="campoTelefoneContato">
                      <InputMask
                        onChange={({ target }) =>
                          handleChangeInputFieldValue("contact", target.value)
                        }
                        type="text"
                        mask="(99) 9 9999 9999"
                      />
                      <S.FloatingText
                        $willFloat={Boolean(ineligibleFields.contact)}
                        className={formErrors.contact ? "errorMsg" : ""}
                      >
                        Telefone de contato
                      </S.FloatingText>
                      {formErrors.contact && (
                        <S.FieldErrorMsg>{formErrors.contact}</S.FieldErrorMsg>
                      )}
                    </label>
                  </S.InputSection>

                  <S.InputSection>
                    <label id="campoEmail">
                      <input
                        onChange={({ target }) =>
                          handleChangeInputFieldValue("email", target.value)
                        }
                        type="text"
                      />
                      <S.FloatingText
                        $willFloat={Boolean(ineligibleFields.email)}
                        className={formErrors.email ? "errorMsg" : ""}
                      >
                        E-mail de contato
                      </S.FloatingText>
                      {formErrors.email && (
                        <S.FieldErrorMsg>{formErrors.email}</S.FieldErrorMsg>
                      )}
                    </label>
                  </S.InputSection>

                  <S.SendButton $formLoading={sendingFormData}>
                    <ButtonPrimary
                      className="firstForm_conversion_desqualificado"
                      loadingText="Enviando..."
                      submit
                      disabled={
                        disabledSimulationBtn ||
                        (!ineligibleFields.contact &&
                          !ineligibleFields.email &&
                          !ineligibleFields.fullName)
                      }
                      showSpinner={sendingFormData}
                    >
                      Enviar
                    </ButtonPrimary>
                  </S.SendButton>
                </S.IneligibleResultForm>

                <S.FormFooter>
                  <p>
                    Ao enviar você autoriza a GUD e seus parceiros a entrar em
                    contato com você via e-mail, telefone ou WhatsApp e declara
                    ter lido a{" "}
                    <a target="__blank" href="/normas-de-privacidade">
                      Termo de Privacidade
                    </a>
                    .
                  </p>
                </S.FormFooter>
              </S.IneligibleFormWrapper>

              <S.RedoSimulation $hideOnMobile={false}>
                <button onClick={() => handleRedoSimulation()} type="button">
                  Simular novamente
                </button>
              </S.RedoSimulation>
            </S.IneligibleFormCol>
          </S.IneligibleWrapper>
        </S.IneligibleRoot>
      ) : (
        <SuccessMessage ineligible={true} />
      )}
    </>
  );
};

export default IneligibleScreen;
