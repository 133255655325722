export const contractContent = [
  {
    text: "Modalidade Desconto Garantido - Energia Fácil",
    link: "https://image.marketing.aurenenergia.com.br/lib/fe3915707564047f7d1778/m/1/c10c2afe-3bb4-4e30-a33e-8bbc00312958.pdf",
    apiInfo: "modalidade_desconto_garantido_energia_facil",
    active: true
  },
  {
    text: "Modalidade Preço Fixo - Energia Fácil",
    link: "https://image.marketing.aurenenergia.com.br/lib/fe3915707564047f7d1778/m/1/6623a6a6-e450-4821-9701-b0d65150e59c.pdf",
    apiInfo: "modalidade_preco_fixo_energia_facil",
    active: true
  },
  {
    text: "Modalidade Desconto Garantido",
    link: "https://image.marketing.aurenenergia.com.br/lib/fe3915707564047f7d1778/m/1/ce58412f-6c9d-40e7-8094-710be9e84a4d.pdf",
    apiInfo: "modalidade_desconto_garantido",
    active: true
  },
  {
    text: "Modalidade Preço Fixo",
    link: "https://image.marketing.aurenenergia.com.br/lib/fe3915707564047f7d1778/m/1/c52954e4-1fdf-4082-a587-04b14b72956a.pdf",
    apiInfo: "modalidade_preco_fixo",
    active: true
  },
];
