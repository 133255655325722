import React, { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../context/globalContext";
import { invisibleButtonStyle, overlayStyle, popupStyle } from "./styles";

const POP_UP_DELAY = 15000;
const eventListener = "mouseleave";
const GTM_POPUP_SHOW = "gtm.popup.show";
const GTM_POPUP_CLICK = "gtm.popup.click";

const ExitPopup: React.FC = () => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [_, setLastPopupSeen] = useState<number>(0);

  const { showSimulationResult } = useContext(GlobalContext);

  const checkIfShouldShow = useMemo(
    () => location.pathname === "/" && !showSimulationResult,
    [location.pathname, showSimulationResult]
  );

  const handleMouseLeave = (event: MouseEvent): void => {
    const mouseOut = event.clientY < 0 || event.clientX < 0;

    if (mouseOut) {
      setLastPopupSeen((prevLastPopupSeen) => {
        const dateNow = Date.now();

        if (dateNow - prevLastPopupSeen > POP_UP_DELAY) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: GTM_POPUP_SHOW,
          });

          setShowPopup(true);
          return dateNow;
        }

        return prevLastPopupSeen;
      });
    }
  };

  useEffect(() => {
    if (!checkIfShouldShow) return;

    document.addEventListener(eventListener, handleMouseLeave);
    return () => {
      document.removeEventListener(eventListener, handleMouseLeave);
    };
  }, [checkIfShouldShow]);

  const followLinkClick = (): void => {
    setShowPopup(false);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: GTM_POPUP_CLICK,
    });
  };

  return (
    <>
      {showPopup && (
        <div className="popup_exit" style={overlayStyle}>
          <div style={popupStyle}>
            <button
              onClick={followLinkClick}
              style={invisibleButtonStyle}
              aria-label="Clique aqui"
            ></button>
          </div>
        </div>
      )}
    </>
  );
};

export default ExitPopup;
