import styled from "styled-components";

export const TitleText = styled.p`
  font-family: "SourceSans3-Regular";
  color: var(--secondary, #fff);
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const StyledLink = styled.a`
  text-align: center;
`;
