import styled from "styled-components"
export const FooterContainer = styled.footer`
  width: 100%;
  background: var(--primary, #000);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "SourceSans3-Regular";
  color: var(--secondary, #fff);
  
  span {
    font-size: 0.875rem;
    line-height: 1rem;
  }
  
  a {
    color: var(--secondary, #fff);
    font-size: 0.875rem;
    line-height: 1.225rem;
  }
`

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
  max-width: 73.25rem;
  padding: 2rem;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 1.5rem;
    gap: 1.5rem;
`

export const FooterCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 20%;
  min-width: 200px;

  @media (max-width: 1200px) {
    width: 100%;
    align-items: center;
    flex: 1 1 100%;
  }
`

export const FooterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    width: 14rem;
  }

  @media (max-width: 1200px) {
    justify-content: center;
    width: 100%;
    align-items: center;

    img {
      width: inherit !important;
      max-width: 14rem;
    }
  }
`

export const Divider = styled.div`
  opacity: 0.5;
  border-right: 1px solid var(--secondary, #fff);
  content: " ";
  display: block;
  left: 0;
  top: 20%;
  height: 100%;
`

export const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.875rem;
  margin-top: 1rem;
`

export const SocialLink = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  span {
    text-decoration: underline;
    color: var(--secondary, #fff);
    font-size: 0.875rem;
  }
`
