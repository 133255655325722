import * as S from "./styles";

const ContractButton = () => {
  return (
    <S.StyledLink target="__blank" href="/contratos" className="contract-link">
      <S.TitleText>Contratos e Documentos</S.TitleText>
    </S.StyledLink>
  );
};

export default ContractButton;
